import React from 'react';
import styled from 'styled-components';
import { graphql } from 'gatsby';
import { GatsbyImage } from 'gatsby-plugin-image';

import SEO from '../../../components/SEO';
import Body from '../../../components/layout/Body';
import ResponsiveContainer from '../../../components/layout/ResponsiveContainer';
import GetStarted from '../../../components/cta/GetStarted';
import Blockquote from '../../../components/frills/Blockquote';

import { ViewportBreakpoints } from '../../../components/cssConstants';

import H2 from '@rotaready/frecl/build/H2';
import H3 from '@rotaready/frecl/build/H3';
import H4 from '@rotaready/frecl/build/H4';
import Text from '@rotaready/frecl/build/Text';
import Pill from '@rotaready/frecl/build/Pill';
import Button from '@rotaready/frecl/build/Button';
import ContactForm from '../../../components/forms/ContactForm';
import OL from "@rotaready/frecl/build/OL";
import TextListItem from "@rotaready/frecl/build/TextListItem";

const HeroContainer = styled.div`
  display: flex;
  flex-direction: column-reverse;

  @media (min-width: ${ViewportBreakpoints.tabletPortraitUpper}) {
    display: block;
    position: relative;
  }
`;

const HeroImageContainer = styled.div`
  height: 450px;

  @media (min-width: ${ViewportBreakpoints.tabletPortraitUpper}) {
    height: 790px;
    width: 50%;
    margin-left: auto;
    transform: translate3d(0, -80px, 0);
  }
`;

const HeroContentContainer = styled.div`
  @media (min-width: ${ViewportBreakpoints.tabletPortraitUpper}) {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    display: flex;
    align-items: center;
    height: calc(100% - 80px);
  }
`;

const Hero = styled.div`
  margin: 40px 0;
  text-align: center;

  @media (min-width: ${ViewportBreakpoints.tabletPortraitUpper}) {
    margin: 0;
    text-align: left;
    width: 40%;
  }
`;

const LogoContainer = styled.div`
  margin: 40px 0;

  > div {
    margin: 0 auto;
  }

  @media (min-width: ${ViewportBreakpoints.tabletPortraitUpper}) {
    > div {
      margin: 0;
    }
  }
`;

const StatsBox = styled.div`
  padding: 0 20px;
  box-shadow: 0 0 8px 2px rgba(71, 91, 104, 0.1);
  background-color: ${({ theme: { colors } }) => colors.grey10};
  border: 1px solid ${({ theme: { colors } }) => colors.grey20};
  border-radius: 3px;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  transform: translateY(-160px);
  flex-wrap: wrap;
`;

const StatItem = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  min-width: 200px;
  height: 80px;
  padding: 20px 0;

  @media (min-width: ${ViewportBreakpoints.tabletPortraitUpper}) {
    padding: 40px 0;
  }
`;

const StatHeadline = styled(H2).attrs({
  uistyle: 'brand160',
})``;

const StatSubtitle = styled(H4).attrs({
  uistyle: 'grey',
})``;

const BodyWrapper = styled.div`
  max-width: 640px;
  margin: -60px auto 0 auto;
`;

const TileTagContainer = styled.div`
  margin-bottom: 40px;

  > div {
    display: inline-block;
    margin-right: 10px;
  }
`;

const TileTag = styled(Pill).attrs({
  size: 'sm',
  uistyle: 'primary',
})``;

const Section = styled.div`
  margin-bottom: 60px;
`;

const Content = styled.div`
  margin-bottom: 30px;
`;

const TextBlock = styled(Text).attrs({
  size: 'lg',
})``;

const PopQuote = styled(H4).attrs({
  uistyle: 'primary',
  fontWeight: 600,
})`
  text-align: center;
`;

const header = {
  backgroundColor: 'rgba(255, 255, 255, 0.9)',
  invertColors: false,
};

class Page extends React.Component {
  render() {
    const {
      data,
    } = this.props;

    return (
      <Body header={header}>
        <SEO
          title="Picsolve Customer Story - Rotaready"
          description="Here's how the world's leading photo capture company rolled out Rotaready across 12 countries in under six months, including a real-time HR integration."
          url="customer-stories/retail-picsolve"
        />

        <HeroContainer>
          <HeroImageContainer>
            <GatsbyImage
              image={data.heroBackground.childImageSharp.gatsbyImageData}
              style={{ height: '100%' }}
              alt="Guests having their photo taken at an attraction"
            />
          </HeroImageContainer>

          <HeroContentContainer>
            <ResponsiveContainer>
              <Hero>
                <TileTagContainer>
                  <TileTag text="Retail" />
                </TileTagContainer>

                <H3 uistyle="primary" text="Customer story" fontWeight={600} />

                <LogoContainer>
                  <GatsbyImage
                    image={data.logo.childImageSharp.gatsbyImageData}
                    style={{ height: '50px' }}
                    alt="Picsolve logo"
                  />
                </LogoContainer>

                <H3 uistyle="brand160" text="Here's how the world's leading photo capture company rolled out Rotaready across 12 countries in under six months, including a real-time HR integration." />
              </Hero>
            </ResponsiveContainer>
          </HeroContentContainer>
        </HeroContainer>

        <ResponsiveContainer>
          <StatsBox>
            <StatItem>
              <StatHeadline text="70" />
              <StatSubtitle text="sites" />
            </StatItem>

            <StatItem>
              <StatHeadline text="1500" />
              <StatSubtitle text="staff" />
            </StatItem>
          </StatsBox>
        </ResponsiveContainer>

        <ResponsiveContainer>
          <BodyWrapper>
            <Section>
              <Content>
                <H3 uistyle="brand160" text="Picsolve" />
              </Content>

              <Content>
                <TextBlock text="If you've ever been to a theme park, zoo, viewing platform, family attraction or even Las Vegas, then chances are your moments of fun are captured by Picsolve, the world's leading capture company. Implementing the technology to do this is tough enough - but then there's ensuring the right colleagues are in the right place to serve the guests at the right time - a different headache altogether." />
              </Content>

              <Content>
                <TextBlock text="Since day one Picsolve used either pen & paper or spreadsheets to manually schedule rotas and monitor staff time. But they're strong believers in providing employees with the right tools to help them do their job to the very best of their ability." />
              </Content>
            </Section>

            <Section>
              <Content>
                <H3 uistyle="brand160" text="An old fashioned approach for a growing business" />
              </Content>

              <Content>
                <TextBlock text="And so, as the business grew - including an acquisition of Freeze Frame, the US market leader - this outdated approach was no longer appropriate and left the business facing a multitude of problems." />
              </Content>

              <Content>
                <TextBlock text="Managers were using inherited rotas from previous colleagues that didn't account for fluctuating demand or past trends. It was 99% art and 1% science, a guessing game at best. This was causing a number of issues, such as poor visibility over projected labour costs and lack of compliance with labour laws." />
              </Content>

              <Content>
                <TextBlock text="In July 2018, with over 70 sites across 12 countries, and over 1,500 staff, many of whom were flexible/seasonal workers, it was time to make a change." />
              </Content>
            </Section>

            <Section>
              <Content>
                <H3 uistyle="brand160" text="A time for change" />
              </Content>

              <Content>
                <TextBlock text="Picsolve set about finding an alternative to this dated process. The business needed a solution to tackle the problems they were facing and had the capacity to grow as the business scaled. They decided on three key criteria that a workforce management system needed to meet." />
              </Content>

              <Content>
                <TextBlock>
                  <OL size="lg" uistyle="normal" iconUistyle="primary">
                    <TextListItem text="Simplicity" />
                    <TextListItem text="Ease of communication" />
                    <TextListItem text="Integration friendliness" />
                  </OL>
                </TextBlock>
              </Content>

              <Content>
                <TextBlock text="Rotaready stood out on all three:" />
              </Content>

              <Content>
                <Blockquote
                  text="Implementing a workforce management system doesn't guarantee efficiency improvements or accurate payroll unless everyone buys into it across the organisation. That's Rotaready's secret weapon - its intuitive design and easy to understand back end meant that everyone from retail hosts, HR managers to executive team members started seeing benefits from day one and wanted to use it. Rotaready is fully integrated with our existing systems - for HR, both employee changes and new joiners flow into Rotaready instantly - and we have live labour data flowing from Rotaready to our internal data warehouse."
                  attribution="Rob Cowell - Managing Director - Asia & Middle East"
                />
              </Content>
            </Section>

            <Section>
              <GatsbyImage
                image={data.inline.childImageSharp.gatsbyImageData}
                alt="Guest having their photo taken against a green screen by a Picsolve team member"
              />
            </Section>

            <Section>
              <Content>
                <H3 uistyle="brand160" text="From pen and paper to the perfect partnership" />
              </Content>

              <Content>
                <TextBlock text="At first glance, rolling out Rotaready across the business seemed like a mammoth challenge for Picsolve, but the group quickly realised Rotaready's Customer Success team was at hand every step of the way. All the complex parts of an international rollout - configuring tax rules per country and real-time integrations with other systems - felt like a smooth process." />
              </Content>
            </Section>

            <Section>
              <PopQuote text="Picsolve chose to implement Rotaready region by region. All 70 sites across 12 countries were up and running with Rotaready within six months, including a seamless real-time integration with Picsolve's existing HR solution and internal data warehouse." />
            </Section>

            <Section>
              <Content>
                <H3 uistyle="brand160" text="A test for success" />
              </Content>

              <Content>
                <TextBlock text="When it came to measuring the success of implementing Rotaready, Picsolve looked to two additional measures; how it had affected labour costs and employee satisfaction." />
              </Content>

              <Content>
                <TextBlock text="In terms of labour costs, Rotaready's cost control feature has given Picsolve full transparency of wage spend, including a projected view of what wages will be for upcoming weeks. This data can then be used to inform staff scheduling, operate within budget and avoid any surprises that may come with payroll at month-end. Rotaready has also helped Picsolve save a significant amount of time on labour administration and increase productivity performance through tighter labour control and hours aligned to customer demand. These changes have improved profitability margins and ensured a strong return on the investment." />
              </Content>

              <Content>
                <TextBlock text="And then there's employee satisfaction. When faced with the question “Do you have the right tools to do your job?”, Picsolve want to ensure every employee answers with, ‘yes'. With just as many features available for retail assistants as there are for those in head office, Rotaready has enabled the business to live up to this." />
              </Content>

              <Content>
                <Blockquote
                  text="The whole team loves using Rotaready. They have gone from limited visibility or flexibility over their shifts, to being able to swap shifts using the mobile app. The increased engagement and control that Rotaready provides has become invaluable to us all."
                  attribution="Rob Boswell - Head of Retail"
                />
              </Content>

              <Content>
                <TextBlock text="In short, Rotaready has revolutionised workforce management for Picsolve." />
              </Content>

              <Content>
                <Blockquote
                  text="Labour management is no longer “admin” – it's a fundamental way Picsolve drives performance."
                  attribution="Rob Cowell - Managing Director - Asia & Middle East"
                />
              </Content>
            </Section>

            <Section>
              <ContactForm
                title="Download the story"
                submitLabel="Download PDF"
                source="customerstories_picsolve"
                label="rotaready.com/customer-stories/retail-picsolve"
                postTo="marketing"
                checkboxes={[
                  {
                    id: 'marketing',
                    text: 'I\'d like to receive updates, offers and new content from Rotaready by email. You can unsubscribe at any time.',
                    required: false,
                    initial: false,
                  },
                ]}
                onSuccess={(
                  <>
                    <TextBlock>
                      <H3 uistyle="brand160" text="Happy reading 📚" />
                    </TextBlock>

                    <TextBlock>
                      <Text size="lg" text="Thank you for downloading this customer story! Click below to get the file." />
                    </TextBlock>

                    <TextBlock>
                      <a href="/files-static/customer-stories/Rotaready-Story-Picsolve.pdf" download>
                        <Button uistyle="primary" text="Download PDF" size="md" />
                      </a>
                    </TextBlock>
                  </>
                )}
              />
            </Section>
          </BodyWrapper>
        </ResponsiveContainer>

        <GetStarted />
      </Body>
    );
  }
}

export default Page;

export const query = graphql`
  query {
    heroBackground: file(relativePath: { eq: "customer-stories/header-picsolve.jpg" }) {
      childImageSharp {
        gatsbyImageData(
          quality: 50
          transformOptions: { cropFocus: CENTER, fit: COVER }
        )
      }
    }

    logo: file(relativePath: { eq: "customer-stories/logo-picsolve.jpg" }) {
      childImageSharp {
        gatsbyImageData(height: 50, quality: 100)
      }
    }

    inline: file(relativePath: { eq: "customer-stories/inline-picsolve.jpg" }) {
      childImageSharp {
        gatsbyImageData(quality: 50)
      }
    }
  }
`;
